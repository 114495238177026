<template>
  <v-card>
    <v-card-text>
      <v-data-table
        v-if="budgetId"
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :show-select="false"
        :items="list"
        :loading="isLoading"
        :items-per-page="1000"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            title=""
            icon=""
            @reload="loadList"
            v-bind="titleBarAttrs"
            hide-edit
            hide-search
            hide-filter-clear
          >
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.actual_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.item_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.error_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.error_ratio="{ value }">
          %<rs-table-cell-number :value="value" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { hasDataTable, hasExport } from "@/view/mixins";

export default {
  mixins: [hasDataTable, hasExport],
  props: {
    /**
     * Budget ID
     */
    budgetId: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      titleBarAttrs: {
        exportUrl: () =>
          `/budget-stats/budgets/${this.budgetId}/by-expense-type`,
        exportParams: () => {
          return {};
        },
      },
      headers: [
        {
          text: "Gider Türü",
          value: "name",
          searchable: false,
          sortable: false,
        },
        {
          text: "Plan Tutar",
          value: "item_amount",
          align: "end",
          searchable: false,
          sortable: false,
        },
        {
          text: "Gerçekleşen Tutar",
          value: "actual_amount",
          align: "end",
          searchable: false,
          sortable: false,
        },
        {
          text: "Gerçekleşen Sapma Oranı",
          value: "error_ratio",
          align: "end",
          searchable: false,
          sortable: false,
        },
        {
          text: "Gerçekleşen Sapma Tutarı",
          value: "error_amount",
          align: "end",
          searchable: false,
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.generateExportColumns(this.headers);
      this.loadList();
    });
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.budgetId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      this.$api
        .query(`/budget-stats/budgets/${this.budgetId}/by-expense-type`)
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    budgetId: {
      handler() {
        this.loadList();
      },
    },
  },
};
</script>
